.cellContent{
    word-wrap: break-word;
    border-right: 1px solid #ccc;
    
}

.adminTableTitle{
    font-size: 0.875rem;
}

.adminNumCell{
    background-color: #fff;
    border: 1px inset #edf5fc;
}