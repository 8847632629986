#askNew input[type="text"] {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius:0rem;
    border-bottom-right-radius:0rem;
    width: 89%;
}

.ask_button {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 0.95rem;
    background: #fff;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
}

button.ask_button:focus {
    outline:none;   
}

button.ask_button:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.copylink_button {
    padding: 0.25rem 0.6rem;
    color: #1B7EDA;
    border: 1px solid #1B7EDA;
    margin: 1.5rem 0.2rem 0rem 0rem;
}

.copylink_button:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.reload_button {
    padding: 0.25rem 0.6rem;
    color: #1B7EDA;
    border: 1px solid #1B7EDA;
    margin: 1.5rem 0.2rem 0rem 0rem;
}

.reload_button:hover {
    transform: scale(1.1);
}

.questionpage_error {
      font-size: 0.75rem;
      border-radius: 0.3rem;
      color: rgb(207, 6, 46);
      position: fixed;
      bottom: 2rem;
      right: 15%;
      left:15%;
      background: rgb(244, 244, 244);
      border: 1px solid rgb(204, 204, 204);
      padding: 0.3rem;
      visibility: hidden;
      -webkit-border-radius: 0.3rem;
      -moz-border-radius: 0.3rem;
      -ms-border-radius: 0.3rem;
      -o-border-radius: 0.3rem;
}

  .questionpage_success {
    font-size: 0.75rem;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: .3rem;
    color: #13b43d;
    padding: .3rem;
    position: fixed;
    bottom: 3rem;
    
    visibility: hidden;
    -webkit-border-radius: .3rem;
    -moz-border-radius: .3rem;
    -ms-border-radius: .3rem;
    -o-border-radius: .3rem;
}

.sz12_text {
    font-size: 0.75rem;
}
.sz14_text {
    font-size: .875rem;
}

@media screen and (min-width: 1024px) {

    #askNew input[type="text"] {
        width: 95%;
    }
}