@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');
* {
  margin: 0;
  padding: 0;
  image-rendering: -webkit-optimize-contrast;
}

html { height: 100%; overflow:auto; }

 body {
  margin: 0;
  color: #1C1C1C;
  /*background-color: #EDF5FC; For browsers that do not support gradients */
  background-image: linear-gradient(to right, #EDF5FC, #EDF5FC  30.51%, rgba(27, 126, 218, 0) 100%) ;
  font-size:16px;
  height: 100%;
}

@media screen and (max-width: 1023px) {
  body {
    background-color: #EDF5FC; 
    background-image: none;
  }
}

#bmc-wbtn {
  top: 18px;
}

.fixed_bottom {
  position:fixed;
  bottom:0;
  height: 50px; 
  margin-top: 50px;
}


/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

