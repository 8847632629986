

#askNew input[type="text"] {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius:0rem;
    border-bottom-right-radius:0rem;
    width: 89%;
}

@media screen and (min-width: 1024px) {

    #askNew input[type="text"] {
        width: 95%;
    }
}

.ask_button {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 0.95rem;
    background: #fff;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
}

button.ask_button:focus {
    outline:none;   

}

button.ask_button:hover {
    transform: scale(1.1);
}

.sz14_text {
    font-size: .875rem;
}

.request_error{
    font-size: 0.75rem;
      border-radius: 0.5rem;
      color: rgb(207, 6, 46);
      position: fixed;
      bottom: 2rem;
      right: 15%;
      left:15%;
      background: rgb(244, 244, 244);
      border: 1px solid rgb(204, 204, 204);
      padding: 0.3rem;
      visibility: hidden;
}