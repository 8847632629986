@media screen and (max-width: 1023px) {
  #hide_this {
    visibility: hidden;
    clear: both;
    float: left;
    /*margin: 10px auto 5px 20px;*/
    width: 0%; 
    display: none;
    background-image: none;
    background:none;
  }

  .home_background{
    background-image: none;
    padding: 0;
  }

  .home_container{
    text-align: center;
  }

  

}

.home_container{
  text-align: left;
}

.sz14_text{
  font-size: 0.875rem;
}

.sz18_text{
  font-size: 1.125rem;
}  

#custom_wrapper {
  min-height:100%;
  margin-bottom:100px;
  border: 3px solid blue;
}

.phone_mockup{
  /*margin-top:-5%;*/
  background-image: url("./questionwave-mockup-new.webp"); 
  background-position: center;
  background-repeat: no-repeat; 
  background-size:contain; 
  image-rendering: -moz-crisp-edges;          /* Firefox */
  image-rendering: -o-crisp-edges;            /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
  image-rendering: crisp-edges;
  height: 100%;
}

.home_background{
  background-image: url("./home_background_img.png"); 
  background-position: center;
  background-repeat: no-repeat; 
  background-size: contain; 
  image-rendering: -webkit-optimize-contrast;
}  

.rotate_this {
  transform: rotate(-12.59deg);
  -webkit-transform: rotate(-12.59deg);
  -moz-transform: rotate(-12.59deg);
  -ms-transform: rotate(-12.59deg);
  -o-transform: rotate(-12.59deg);
}

.homepage_error {
  font-size: .75rem;
  border-radius: 0.5rem;
  color: #CF062E;
  left: 25%;
  right: 25%;
  background: #f4f4f4;
  border: 1px solid #ccc;
  padding: .3rem;
  visibility: hidden;
}



.sz35_text{
  font-size: 2.2rem;
}  

@media screen and (max-width: 1023px) {


  .home_container{
    text-align: center;
  }

  

}
