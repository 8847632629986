
#editTitle .styles_Editext__edit_button__hthOZ::before{
    content:url(./edit_icon.svg);
    margin:0;
    
}

#editTitle .styles_Editext__edit_button__hthOZ :focus{
    outline: none;
    outline: 1px solid #1b7eda;;
    
}


#editTitle .styles_Editext__save_button__3WN6q::before{
    content:url(./save_icon.svg);
    margin:0;
}

#editTitle .styles_Editext__cancel_button__259hb::before{
    content:url(./cancel_icon.svg);
    margin:0;
}

/* input[editext="input"] {
   width: 500px;
} */

button[editext='edit-button'] {
    border: none;
    color:#1B7EDA;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
}

button[editext='save-button'], button[editext='cancel-button'] {
    border: none;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
}

button[editext='edit-button']:focus, button[editext='save-button']:focus, button[editext='cancel-button']:focus {
    outline: none;
}
  
button[editext='edit-button']:hover, button[editext='cancel-button']:hover, button[editext='save-button']:hover {
    transform: scale(1.1);
    background:none;
    cursor: pointer;
}

input[editext="input"] {
    border: 1px solid rgb(28, 28, 28,0.1);
    border-radius: 0.375rem;
    font-weight: 800;
    -webkit-border-radius: 0.375rem;
    -moz-border-radius: 0.375rem;
    -ms-border-radius: 0.375rem;
    -o-border-radius: 0.375rem;
}
.displayLink{
    background-color: #F4F4F4;
    color: #333333;
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem;
    border: 1px solid #B0B0B0;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}
.linkDetails{
    width: 85%;
    word-wrap: break-word;
}

.successMessageStyle {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: .3rem;
    color: #13b43d;
    padding: .3rem;
    font-size: .75rem;
    left: 28%;
    right: 28%;
    visibility: hidden;

}
.sz12_text{
    font-size: 0.75rem;
  }
.sz14_text{
    font-size: 0.875rem;
  }
  
  .sz18_text{
    font-size: 1.125rem;
  }  

  @media screen and (max-width: 1023px) {
    /* input[editext="input"] {
        width: 300px;
     }  */
    .successMessageStyle {
        left: 25%;
        right: 25%;
    }
  }