

.blue_button {
    text-align: center;
    width: 100%;
    height: 3rem;
    color: #fff;
    background-color: #1B7EDA;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 1.25rem;
}

.white_button {
    text-align: center;
    width: 100%;
    height: 3rem;
    color: #1B7EDA;
    background-color: #fff;
    border: 1px solid #1B7EDA;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    font-size: 1.25rem;
}

#gdpr-cookie-message-wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.40);
    z-index: 500;
}

div#gdpr-cookie-message {
    position: absolute;
    right: 0px;
    bottom: 50px;
    width: 500px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 20px 24px 10px rgba(0, 0, 0, 0.04);
    z-index: 65500;
    left: 50%;
    margin-left: -250px;
}

div#gdpr-cookie-message h4 {
    color: #212121;
    font-size: 20px;
    font-weight: 800;
    float: left;
    width: 100%;
}

div#gdpr-cookie-message p {
    font-size: 14px;
    line-height: 19px;
    color: #8f9496;
    float: left;
    width: 100%;
    padding-top: 10px;
}

div#gdpr-cookie-message a {
    font-weight: bold;
    cursor: pointer;
}

button#gdpr-cookie-accept {
    border-radius: 4px;
    color: #FFF;
    clear: both;
    display: inline-block;
    background-color: #1B7EDA;
    -webkit-font-smoothing: antialiased;
    font-family: 'Inter', sans-serif;
    border: none;
    outline: none;
    margin-top: 11px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
}

